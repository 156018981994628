<template>
  <div>
  <CCard>
   
  <CCardBody>
    <div class="clearfix">
      <button type="button" class="btn btn-primary float-xl-right" @click="navToNewSymptoms">New Input Field</button>
    </div>

    <CDataTable :items="items"  
    :fields="fields"
      striped
      clickable-rows
      column-filter
       table-filter
        items-per-page-select
      :items-per-page="20"
       sorter
       ref="vuetable"
      pagination
      
       >

        <template #needRemarks-filter>
          <select
            class="form-control form-control-sm"
            @input="$refs.vuetable.columnFilterEvent('needRemarks', $event.target.value, 'input')"
          >
            <option value selected="selected">Any</option>
            <option value="true"> Yes </option>
            <option value="2">No</option>
          </select>
        </template>
       
      <template #index="data">
       <td>
         {{data.index +1}}
         </td>
     </template>

      <template #createdTime="{item}">
        <td class='font-weight-bold'
          v-if="item.createdTime"
        >{{ item.createdTime |dateParse()| dateFormat("MMM D, YYYY hh:mm A") }}</td>
        <td v-else>--</td>
      </template>
     <!-- <template #checkOptionName="{item}">
        <td class='font-weight-bold'
          v-if="item.checkOptionName"
        >{{ item.checkOptionName }}</td>
        <td v-else>--</td>
      </template> -->
        <template #needRemarks="{item}">
        <td class='font-weight-bold'
          v-if="item.needRemarks == true">
            Yes  </td>
          <td class='font-weight-bold' v-else>No </td>
       
        
      </template>  

    
    
       <template #hint="{item}">
        
        <td class='font-weight-bold'
          v-if="item.hint"
        >{{ item.hint}}</td>
        <td  v-else>--</td>
     </template> 
     
        <template #show_details="{item}">
        <td class="py-2">
        <CDropdown
                  togglerText="Actions"
                  color="info"
                >
                 
             <CDropdownItem :to="{name: 'InputField', params: {inputFieldId: item.inputFieldId, mode: 'view'}}">View</CDropdownItem> 
                <CDropdownItem :to="{name: 'InputChecks', params: {inputFieldId: item.inputFieldId}}">Input Checks</CDropdownItem> 
                <ConfirmationModal
                      :parent="$refs.modalArea"
                      :description="'Are you sure to Delete'"
                      @on:ok="deleteField(item.inputFieldId)"
                    ></ConfirmationModal>

                </CDropdown> 

        
        </td>
      </template>
      </CDataTable>

  </CCardBody>
  <div ref="modalArea"></div>
  <CCardFooter align="right">

            <CButton class="mr-2" type="Cancel" size="sm" color="danger"  @click="cancel()">
              <CIcon name="cil-ban" /> Cancel
            </CButton>
          </CCardFooter>
 </CCard>
 </div>
</template>

<script>
const fields = [
  'index',
   { key: "inputName",label: "Input Name", _classes: 'font-weight-bold', _style: "min-width:200px" },
 { key: "inputTypeName",label: "Input Type", _classes: 'font-weight-bold', _style: "min-width:200px" },
  { key: "priority", _classes: 'font-weight-bold', _style: "min-width:200px" },
   { key: "needRemarks",label: "Remarks", _classes: 'font-weight-bold', _style: "min-width:200px" },
    { key: "hint", _classes: 'font-weight-bold', _style: "min-width:200px" },
   
  { key: "createdTime",label: "Created Date & Time", _classes: 'font-weight-bold', _style: "min-width:200px" },
   { key: "show_details",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
  
];
import ConfirmationModal from "../../containers/ConfirmationModal";
export default {
  name: "SubSectionInputFields",
  components: {
    ConfirmationModal,
  },
  data() {
    return {
      items: [],
      fields,
      details: [],
      collapseDuration: 0,
    };
  },
  methods: {
    getInputField() {
      this.apiGetSecure(process.env.VUE_APP_API_HOST + "/emapp/web/secure/workPermit/sections/subSection/Inputfields/"+this.$route.params.subSectionId)
        .then((response) => response.json())
        .then((data) => (this.items = data));
    },
   
     cancel() {
   
            // this.$router.push({ name: "Sections", params: {permitFormId: this.$route.params.permitFormId }});
         window.history.back();
   },
    deleteField(inputFieldId) {
      console.log("delete called with", inputFieldId);
     this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/delete/WorkPermit/section/InputFields/"+ inputFieldId
      )
        .then((response) => response.json())
        .then((data) => {
          this.getInputField();
             this.$nextTick(() => {
             this.$refs.vuetable.refresh();
           });
         window.location.reload();
        });
    },
    navToNewSymptoms(){
       this.$router.push({ name: "SubSectionInputField", params: {subSectionId: this.$route.params.subSectionId,sectionId: this.$route.params.sectionId, mode: "new" } } );
    }
  },
  mounted() {
    this.getInputField();
  },
};
</script>